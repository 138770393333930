$(document).ready(function() {

	$(function () {

		$('#mobile-nav-button').click(function() {
			$(this).toggleClass('open');
			$('.mobile-nav-container').toggleClass('open');
		});

	});
});
