var filterMethods = {
	data:{
		activeTags:[],
		activeYearTags:[],
		$parent: {},
		$searchField: {},
		searchStrings:[]
	},

	init($parent, $searchField, $filterItems){
		filterMethods.data.$parent = $parent;
		filterMethods.data.$searchField = $searchField;
		filterMethods.makeSearchable();
		filterMethods.data.$searchField.on('input', filterMethods.handleSearchInput);
		$filterItems.on('click', filterMethods.handleClick );
	},
	makeSearchable(){
		var $items = $('.recommendation-item', filterMethods.data.$parent);
		var len = $items.length;

		for (var i = 0; i < len; i++) {
			var compound = '';
			var $item = $items.eq(i);
			compound += $item.find('.title').text();
			compound += ' ';
			compound += $item.find('.date-range').text();
			filterMethods.data.searchStrings.push(compound);
		}
	},
	handleClick(){
		var tag = $(this).attr('data-tag');
		var yeartag = $(this).attr('data-yeartag');

		if(tag){
			filterMethods.toggleActive($(this), tag, false);
		}
		if(yeartag){
			filterMethods.toggleActive($(this), yeartag, true);
		}

	},

	handleSearchInput() {
		var search = filterMethods.data.$searchField.val();
		// console.log('change :', search);
		if (search.length > 2){
			filterMethods.filterSearchWord(search);
		} else {
			var $items = $('.recommendation-item', filterMethods.data.$parent);
			$items.toggleClass('not-in-search-result', false);


		}
	},

	filterSearchWord(search){
		var tokenized = search.split(' ');
		var relevantIndexes = filterMethods.data.searchStrings.map(function(value, index){ return index});
		while (tokenized.length > 0) {
			var token = tokenized.pop();
			if (token.length < 3) {
				continue;
			}

			for (var i = relevantIndexes.length -1; i >= 0; i--) {
				var testString = filterMethods.data.searchStrings[relevantIndexes[i]];
				if (!testString.match(new RegExp(token, 'i'))) {
					relevantIndexes.splice(i, 1);
				}
			}
		}
		var $items = $('.recommendation-item', filterMethods.data.$parent);
		$items.toggleClass('not-in-search-result',true);

		for (let j=0, len = relevantIndexes.length; j <len; j++){
			$items.eq(relevantIndexes[j]).toggleClass('not-in-search-result',false);
		}

	},

	toggleActive($this, tag, isYearTag) {
		if(isYearTag) {
			var index = filterMethods.data.activeYearTags.indexOf(tag);
			if ( index !== -1) {
				filterMethods.data.activeYearTags.splice(index, 1);
				$this.toggleClass('active', false);
				filterMethods.resetVisibleItems();
				return;
			}
			$this.toggleClass('active', true);
			filterMethods.data.activeYearTags.push(tag);
			console.log('active yeartags', filterMethods.data.activeYearTags);
			filterMethods.resetVisibleItems();
		} else {
			var index = filterMethods.data.activeTags.indexOf(tag);
			if ( index !== -1) {
				filterMethods.data.activeTags.splice(index, 1);
				$this.toggleClass('active', false);
				filterMethods.resetVisibleItems();
				return;
			}
			$this.toggleClass('active', true);
			filterMethods.data.activeTags.push(tag);
			console.log('active tags', filterMethods.data.activeTags);
			filterMethods.resetVisibleItems();
		}

	},
	resetVisibleItems() {
		$('.recommendation-item', filterMethods.data.$parent).toggleClass('is-flex', false).toggleClass('is-flex-hidden', true);


		var lenYear = filterMethods.data.activeYearTags.length;
		var len = filterMethods.data.activeTags.length;



		if (lenYear > 0 && len > 0) {
			for (var i = 0; i < lenYear; i++) {
				for (var j = 0; j < len; j++) {
					$('.recommendation-item.multitag-' + filterMethods.data.activeYearTags[i] + filterMethods.data.activeTags[j]).toggleClass('is-flex', true).toggleClass('is-flex-hidden', false);
				}

			}
		} else if (lenYear > 0) {
			for (var i = 0; i < lenYear; i++) {
				$('.recommendation-item.yeartag-' + filterMethods.data.activeYearTags[i]).toggleClass('is-flex', true).toggleClass('is-flex-hidden', false);
			}
		} else if (len > 0) {
			for (var i = 0; i < len; i++) {
				$('.recommendation-item.tag-' + filterMethods.data.activeTags[i]).toggleClass('is-flex', true).toggleClass('is-flex-hidden', false);
			}
		} else {
			$('.recommendation-item',filterMethods.data.$parent).toggleClass('is-flex', true).toggleClass('is-flex-hidden', false);
		}

		$('hiddendiv > div').unwrap();
		$('.is-flex-hidden').wrap('<hiddendiv></hiddendiv>');
	}

};

function paginateChildren(parentElement) {

	var page = 1;
	var currentElements;
	var offsetStart;
	var offsetEnd;
	var currentPage = 1;
	var elementsPerPage = 9;
	var elements = parentElement.find($('.recommendation-item'));
	var nmbrOfPages = Math.ceil(elements.length / elementsPerPage);

	var displayNav = function () {
		htmlNav = '<div class="paginationNav pull-right">';
		htmlNav += '<a href="#" title="Previous" rel="" class="prev"><i class="far fa-angle-double-left"></i> vorige </a>';
		htmlNav += '<span>' + currentPage + ' van de ' + nmbrOfPages + ' paginas' + '</span>';
		htmlNav += '<a href="#" title="Next" rel="" class="next active"> volgende <i class="far fa-angle-double-right"></i></a>';
		htmlNav += '</div>';

		console.log('parent')

		if (!$(parentElement).find('.paginationNav').length) {
			$(parentElement.parent()).append(htmlNav);
		}
	};

	$(parentElement.parent()).on('click', '.paginationNav a.prev', function (e) {
		e.preventDefault();
		page = currentPage > 1 ? parseInt(currentPage) - 1 : 1;
		displayPage(page);
	});

	$(parentElement.parent()).on('click', '.paginationNav a.next', function (e) {
		e.preventDefault();
		page = currentPage < nmbrOfPages ? parseInt(currentPage) + 1 : nmbrOfPages;
		displayPage(page);
	});

	var displayPage = function (page) {
		if (currentPage != page || page == 1) {
			currentPage = parseInt(page);
			$('.paginationNav span', parentElement.parent()).html(currentPage + ' van de ' + nmbrOfPages + ' paginas');
			var $prevButton = $('.paginationNav a.prev');
			var $nextButton = $('.paginationNav a.next');
			if (currentPage == 1 && nmbrOfPages > 1) {
				if ($prevButton.hasClass('active')) {
					$prevButton.removeClass('active');
				}
				$nextButton.addClass('active');
			} else if (currentPage > 1 && currentPage < nmbrOfPages) {
				$prevButton.addClass('active');
				$nextButton.addClass('active');
			} else if (nmbrOfPages > 1 && currentPage == nmbrOfPages) {
				$prevButton.addClass('active');
				if ($nextButton.hasClass('active')) {
					$nextButton.removeClass('active');
				}
			}
			offsetStart = (page - 1) * elementsPerPage;
			offsetEnd = page * elementsPerPage;

			if (currentElements) {

				for (var i = 0; i < currentElements.length; i++) {
					$(currentElements[i]).toggleClass('is-flex', false).toggleClass('is-flex-hidden', true);
				}

			} else {
				for (var i = 0; i < elements.length; i++) {
					$(elements[i]).toggleClass('is-flex', false).toggleClass('is-flex-hidden', true);
				}
			}
			currentElements = elements.slice(offsetStart, offsetEnd);

			for (var i = 0; i < currentElements.length; i++) {
				$(currentElements[i]).toggleClass('is-flex', true).toggleClass('is-flex-hidden', false);
			}
		}
	};
	if (page.length <= 0 || page < 1 || page > nmbrOfPages) {
		page = 1;
	}
	displayPage(page);
	if (nmbrOfPages > 1) {
		displayNav();
	}
}


$(document).ready(function() {

	filterMethods.init($('.recommendation-items'), $('.search-field'), $('.filter .filter-item'));

	paginateChildren($('.recommendation-items'));

	// $('.recommendation-item', filterMethods.data.$parent).toggleClass('is-flex', false).toggleClass('is-flex-hidden', true);

});