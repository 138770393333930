function cookiebarCheck() {
    var cookiesAccepted = document.cookie.replace(/(?:(?:^|.*;\s*)cookiebar\s*\=\s*([^;]*).*$)|^.*$/, "$1");

    if(cookiesAccepted !== 'cookiesAccepted' && cookiesAccepted !== 'cookiesNotAccepted') {
        showCookiebar();
    }
}

function showCookiebar() {
    $('.cookiebar').removeClass('hidden');
    $('.cookiebar').addClass("animated fadeInUp faster");

    $('#cookiebar-button').on('click', function(event) {
        event.preventDefault();

        $('.cookiebar').removeClass("fadeInUp");
        $('.cookiebar').addClass("fadeOutDown");
        $('.cookiebar').on('animationend', function() {
            $('.cookiebar').addClass("hidden");
        });
        setCookie('cookiebar','cookiesAccepted');
    });

    $('#cookiebar-button-no').on('click', function(event) {
        event.preventDefault();

        $('.cookiebar').removeClass("fadeInUp");
        $('.cookiebar').addClass("fadeOutDown");
        $('.cookiebar').on('animationend', function() {
            $('.cookiebar').addClass("hidden");
        });
        setCookie('cookiebar','cookiesNotAccepted');
    });
}

/**
 * Write cookieBar's cookie when user accepts cookies :)
 * @param {string} name - cookie name
 * @param {string} value - cookie value
 * @return null
 */
function setCookie(name, value) {
    var exdays = 30;
    var exdate = new Date();
    exdate.setDate(exdate.getDate() + parseInt(exdays));
    var cValue = encodeURI(value) + ((exdays === null) ? '' : '; expires=' + exdate.toUTCString() + ';path=/');

    document.cookie = name + '=' + cValue;
}

$(document).ready(function() {
    cookiebarCheck();
});
