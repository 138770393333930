var newsFilterMethods = {
	data:{
		activeTags:[],
		$parent: {},
	},

	init($parent, $filterItems){
		newsFilterMethods.data.$parent = $parent;
		$filterItems.on('click', newsFilterMethods.handleClick );
	},
	handleClick(){
		var tag = $(this).attr('data-tag');
		newsFilterMethods.toggleActive($(this), tag);
	},

	toggleActive($this, tag) {

		var index = newsFilterMethods.data.activeTags.indexOf(tag);
		if ( index !== -1) {
			newsFilterMethods.data.activeTags.splice(index, 1);
			$this.toggleClass('active', false);
			newsFilterMethods.resetVisibleItems();
			return;
		}
		$this.toggleClass('active', true);
		newsFilterMethods.data.activeTags.push(tag);
		// console.log('active tags', newsFilterMethods.data.activeTags);
		newsFilterMethods.resetVisibleItems();

	},
	resetVisibleItems() {
		$('.news-item-wrapper', newsFilterMethods.data.$parent).toggleClass('is-flex', false).toggleClass('is-flex-hidden', true);
		var len = newsFilterMethods.data.activeTags.length;
		if (len > 0) {
			for (var i = 0; i < len; i++) {
				$('.news-item-wrapper.tag-' + newsFilterMethods.data.activeTags[i]).toggleClass('is-flex', true).toggleClass('is-flex-hidden', false);
			}
		} else {
			$('.news-item-wrapper',newsFilterMethods.data.$parent).toggleClass('is-flex', true).toggleClass('is-flex-hidden', false);
		}

		$('hiddendiv > div').unwrap();
		$('.is-flex-hidden').wrap('<hiddendiv></hiddendiv>');

	}

};

function paginateNews(parentElement) {

	var page = 1;
	var currentElements;
	var offsetStart;
	var offsetEnd;
	var currentPage = 1;
	var elementsPerPage = 8;
	var elements = parentElement.find($('.news-item-wrapper'));
	var nmbrOfPages = Math.ceil(elements.length / elementsPerPage);

	var displayNav = function () {
		htmlNav = '<div class="paginationNav pull-right">';
		htmlNav += '<a href="#" title="Previous" rel="" class="prev"><i class="far fa-angle-double-left"></i> vorige </a>';
		htmlNav += '<span>' + currentPage + ' van de ' + nmbrOfPages + ' paginas' + '</span>';
		htmlNav += '<a href="#" title="Next" rel="" class="next active"> volgende <i class="far fa-angle-double-right"></i></a>';
		htmlNav += '</div>';

		console.log('parent')

		if (!$(parentElement).find('.paginationNav').length) {
			$(parentElement.parent()).append(htmlNav);
		}
	};

	$(parentElement.parent()).on('click', '.paginationNav a.prev', function (e) {
		e.preventDefault();
		page = currentPage > 1 ? parseInt(currentPage) - 1 : 1;
		displayPage(page);
	});

	$(parentElement.parent()).on('click', '.paginationNav a.next', function (e) {
		e.preventDefault();
		page = currentPage < nmbrOfPages ? parseInt(currentPage) + 1 : nmbrOfPages;
		displayPage(page);
	});

	var displayPage = function (page) {
		if (currentPage != page || page == 1) {
			currentPage = parseInt(page);
			$('.paginationNav span', parentElement.parent()).html(currentPage + ' van de ' + nmbrOfPages + ' paginas');
			var $prevButton = $('.paginationNav a.prev');
			var $nextButton = $('.paginationNav a.next');
			if (currentPage == 1 && nmbrOfPages > 1) {
				if ($prevButton.hasClass('active')) {
					$prevButton.removeClass('active');
				}
				$nextButton.addClass('active');
			} else if (currentPage > 1 && currentPage < nmbrOfPages) {
				$prevButton.addClass('active');
				$nextButton.addClass('active');
			} else if (nmbrOfPages > 1 && currentPage == nmbrOfPages) {
				$prevButton.addClass('active');
				if ($nextButton.hasClass('active')) {
					$nextButton.removeClass('active');
				}
			}
			offsetStart = (page - 1) * elementsPerPage;
			offsetEnd = page * elementsPerPage;

			if (currentElements) {

				for (var i = 0; i < currentElements.length; i++) {
					$(currentElements[i]).toggleClass('is-flex', false).toggleClass('is-flex-hidden', true);
				}

			} else {
				for (var i = 0; i < elements.length; i++) {
					$(elements[i]).toggleClass('is-flex', false).toggleClass('is-flex-hidden', true);
				}
			}
			currentElements = elements.slice(offsetStart, offsetEnd);

			for (var i = 0; i < currentElements.length; i++) {
				$(currentElements[i]).toggleClass('is-flex', true).toggleClass('is-flex-hidden', false);
			}
		}
	};
	if (page.length <= 0 || page < 1 || page > nmbrOfPages) {
		page = 1;
	}
	displayPage(page);
	if (nmbrOfPages > 1) {
		displayNav();
	}
}


$(document).ready(function() {
	newsFilterMethods.init($('.news-items'), $('.filter .filter-item'));

	paginateNews($('.paginate'));
	paginateNews($('.paginate-columns'));

});